
import {computed, defineComponent, ref} from "vue";
import bottomNav from "@/components/home/bottomNav.vue";
import topNav from "@/components/home/topNav.vue";
import ExtraInfoPage from "@/views/auth/ExtraInfoPage.vue";
import {useLoading} from "vue-loading-overlay";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {key} from "@/store/index";
import * as toastr from "toastr";
import {validateEmail} from "@/utils/util";
import User from "@/models/user";
import FuncUtils from "@/utils/funcUtils";
import { useI18n } from 'vue-i18n'


export default defineComponent({
  name: 'Login',
  components: {
    bottomNav,
    topNav,
    'extra-info-page': ExtraInfoPage
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const loginRef = ref(null);
    const store = useStore(key);
    store.dispatch('country/fetch')
    const router = useRouter();
    const $loader:any = useLoading();
    const user = ref<User>(new User());
    const { t } = useI18n()
    let extraStep = ref(false);
    const fieldsToExclude = ['id', 'deviceTokens', 'ipAddress', 'createdAt', 'updatedAt'];

    const loginWithEmailAndPassword = async () => {
      if (!validateFields()) return false
    const loader =  $loader.show({container: loginRef.value});
      try {
        let response = await store.dispatch('auth/signInWithEmailAndPassword', {
          email: email.value,
          password: password.value
        });
        if (response.status === 200) {
          await toastr.success(t('loginPage.loginMessage'));
          return  router.push({name: 'Home'})
        }
      }catch (e) {
        await  toastr.error(e.message);
      }finally {
        loader.hide();
      }

    }

    const signInWithGoogle = async () => {
      const loader = $loader.show({container: loginRef.value});
      try {
        const response = await store.dispatch('auth/signInWithGoogle');
        if (response.isNewUser) {
          user.value = response.user;
          extraStep.value = true;
          return;
        } else if (response.status) {
          await toastr.success(response.message);
          return router.push({name: 'Home'})
        }
      }catch (e) {
        await toastr.error(e.message);
      }finally {
        loader.hide();
      }
    }

    const signInWithApple = async () => {
    const loader =  $loader.show({container: loginRef.value});

    try {
      let response = await store.dispatch('auth/signInWithApple');
      if (response.isNewUser) {
        user.value = response.user;
        extraStep.value = true;
        return;
      } else if (response.status) {
        await toastr.success(response.message);
        return router.push({name: 'Home'})
      }
    }catch (e) {
      await toastr.error(e.message)
    }finally {
      loader.hide();
    }

    }

    const completeExtraStep = async (context) => {
      user.value = context
      if (!FuncUtils.validateFields(user.value, [...fieldsToExclude, 'password'])) {
        return toastr.warning(t('loginPage.validationMessage'));
      }
     const loader = $loader.show({container: loginRef.value});

      try {
        const response = await store.dispatch('auth/completeExtraStep', {user: user.value});
        if (response.status === 200) {
          await toastr.success(t('loginPage.loginMessage'));
          return router.push({name: 'Home'})
        }
      }catch (e) {
        await  toastr.error(e.message);
      }finally {
        loader.hide();
      }

    }


    function validateFields() {
      if (!validateEmail(email.value)) {
        toastr.warning(t('loginPage.invalidEmail'));
        return false
      }
     else if (!password.value) {
        toastr.warning(t('loginPage.invalidPassword'));
        return false
      }
      return true
    }

    const countries = computed(() => store.getters['country/getCountries'])

    return {
      email,
      password,
      loginWithEmailAndPassword,
      signInWithGoogle,
      signInWithApple,
      countries,
      extraStep,
      user,
      loginRef,
      completeExtraStep,
    }
  },
})
